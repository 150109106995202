body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI','sans-serif', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button:focus {
    outline: none !important;
}

.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.1)!important;
}

.MuiTooltip-popper {
  z-index: 10 !important;
}

.carousel .carousel-control-next,
.carousel .carousel-control-prev {
    top: 40%;
    bottom: 40%;
    width: 50px;
    background: rgb(0 0 0 / 7%);
}

#st-2.st-right {
  z-index: 999;
}

::-webkit-scrollbar-thumb
{
    background-color: gray;
    -webkit-border-radius: 6px;
    height: 1rem;
    border-radius: 6px;
    -webkit-box-shadow: inset 0 0 0.5rem rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-track
{
    height: 1rem;
}
::-webkit-scrollbar
{
    width: 0.6rem;
    display: block;
    height: 1.0rem;
    background-color: #f2f2f2;
}

::-moz-selection { /* Code for Firefox */
    color: #fff;
    background: rgb(255, 141, 87, 0.7);
  }
  
  ::selection {
    color: #fff;
    background: rgb(255, 141, 87, 0.7);
  }

#google_translate_element {
  border: none;
  margin-left: 1em;
}

.goog-te-gadget-icon{
  display: none;
}

.goog-te-menu-value{
  color: #605f5f;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 14px;
}